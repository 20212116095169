import React, { useCallback, useContext, useState } from 'react';
import {
  addJobToUser,
  removeJobFromUser,
  sendClickTrackingEvent,
} from '../../api/talent-rerouting';
import DropdownInput, {
  dropdownOption,
} from '../../components/form-components/DropdownInput';
import Footer from '../../components/Footer';
import JobCard from '../../components/JobCard';
import PageTitle from '../../components/PageTitle';
import { UserContext } from '../../context/User';
import useOnScreen from '../../hooks/UseOnScreenHook';
import {
  ClickTrackingMetadata,
  JobSearchDataResults,
  JobSearchJob,
  Path,
  SavedJob,
  Theme,
} from '../../types/talent-rerouting';
import SearchFooter from './SearchFooter';

const observerOptions = {
  root: null,
  rootMargin: '0px',
  threshold: 0.1,
};

const JobSearch = ({
  selectedPath = '',
  setSelectedPath,
  careerPaths = [],
  jobs = {
    same: [],
    similar: [],
    stretch: [],
  },
  dropdownOptions = [],
  savedJobs = [],
  theme,
}: {
  selectedPath: string;
  setSelectedPath: (job: string) => void;
  careerPaths: Path[];
  jobs: JobSearchDataResults;
  savedJobs: SavedJob[];
  dropdownOptions: dropdownOption[];
  theme?: Theme;
}) => {
  const { user, authObject, setUserJobs } = useContext(UserContext);
  const [sameRef, sameIsVisible] = useOnScreen(observerOptions);
  const [similarRef, similarIsVisible] = useOnScreen(observerOptions);
  const [stretchRef, stretchIsVisible] = useOnScreen(observerOptions);
  const [expanded, setExpanded] = useState(-1);

  const filterJobs = useCallback(
    (jobs: JobSearchJob[], onetCodes: string[]) =>
      jobs.filter((job) =>
        onetCodes?.length
          ? onetCodes.some((code) => job.onet_soc.startsWith(code))
          : true,
      ),
    [],
  );

  const onetCodes =
    careerPaths.find((path) => path.careerPathId === selectedPath)?.onetCodes ??
    [];

  const handleExpansion = (id: number) => setExpanded(expanded === id ? 0 : id);

  const handleClickTracking = (job: JobSearchJob) => {
    const metadata: ClickTrackingMetadata = {
      clickSource: 'JobSearch',
      url: job.apply_url,
      careerPath: selectedPath,
      careerPathOnetCodes: onetCodes,
    };
    sendClickTrackingEvent(user, metadata, authObject.accessToken);
  };

  const handleSaveClick = (
    job: JobSearchJob,
    savedJob: number,
    isSameOrSimilarJob: boolean,
  ) => {
    if (isSameOrSimilarJob) {
      window.open(job.apply_url, '_blank');
    } else if (savedJob !== -1) {
      removeJobFromUser(
        user.id,
        savedJobs[savedJob].id,
        authObject.accessToken,
        setUserJobs,
      );
    } else {
      addJobToUser(user.id, job, authObject.accessToken, setUserJobs);
    }

    handleClickTracking(job);
  };

  const mapJobToCard = (
    job: JobSearchJob,
    savedJobs: SavedJob[],
    isSameOrSimilarJob = false,
  ) => {
    const savedJob = savedJobs.findIndex(
      (savedJob) => savedJob.jobPostingId === job.job_posting_id,
    );

    return (
      <JobCard
        job={job}
        key={job.job_posting_id}
        expanded={expanded === job.job_posting_id}
        onChange={handleExpansion}
        theme={theme}
        isSameOrSimilarJob={isSameOrSimilarJob}
        onSaveClick={() => handleSaveClick(job, savedJob, isSameOrSimilarJob)}
        savedJob={savedJob !== -1}
      />
    );
  };

  const sameJobs = filterJobs(jobs.same, onetCodes).map((job) =>
    mapJobToCard(job, savedJobs, true),
  );
  const similarJobs = filterJobs(jobs.similar, onetCodes).map((job) =>
    mapJobToCard(job, savedJobs, true),
  );
  const stretchJobs = filterJobs(jobs.stretch, onetCodes).map((job) =>
    mapJobToCard(job, savedJobs),
  );

  const onSelectionChange = (jobId: string) => {
    setSelectedPath(jobId);
  };

  const scrollTo = (index: number) => {
    if (index === 0) {
      sameRef.current?.scrollIntoView({ behavior: 'smooth' });
    } else if (index === 1) {
      similarRef.current?.scrollIntoView({ behavior: 'smooth' });
    } else if (index === 2) {
      stretchRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div style={{ maxWidth: '600px', width: '100vw' }}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ background: 'white', padding: '8px 16px' }}>
          <DropdownInput
            label="Filter"
            value={selectedPath}
            options={dropdownOptions}
            setValue={onSelectionChange}
            showErrors={false}
            style={{ minWidth: '100%' }}
          />
        </div>

        <div
          style={{
            margin: '0 16px',
          }}
        >
          <div>
            {sameJobs?.length > 0 && (
              <div key="same-section">
                <PageTitle
                  scrollRef={sameRef}
                  heading="Same"
                  description="Jobs that require no additional training/certification."
                  titleStyle={{ marginBottom: '8px' }}
                  descriptionStyle={{ marginTop: 0 }}
                />
                {sameJobs}
              </div>
            )}

            {similarJobs?.length > 0 && (
              <div key="similar-section">
                <PageTitle
                  scrollRef={similarRef}
                  heading="Similar"
                  description="Jobs that require similar training/certification."
                  titleStyle={{ marginBottom: '8px' }}
                  descriptionStyle={{ marginTop: 0 }}
                />
                {similarJobs}
              </div>
            )}

            {stretchJobs?.length > 0 && (
              <div key="stretch-section">
                <PageTitle
                  scrollRef={stretchRef}
                  heading="Growth"
                  description="Jobs that require additional training/certification."
                  titleStyle={{ marginBottom: '8px' }}
                  descriptionStyle={{ marginTop: 0 }}
                />
                {stretchJobs}
              </div>
            )}
            {sameJobs.length === 0 &&
              similarJobs.length === 0 &&
              stretchJobs.length === 0 && (
                <div>
                  <PageTitle
                    heading="No Jobs Found"
                    description="No jobs were found at this time."
                  />
                </div>
              )}
          </div>
          <Footer style={{ marginBottom: '50px' }} />

          <SearchFooter
            onClick={scrollTo}
            sameIsVisible={sameIsVisible}
            similarIsVisible={similarIsVisible}
            stretchIsVisible={stretchIsVisible}
            sameCount={sameJobs.length}
            similarCount={similarJobs.length}
            stretchCount={stretchJobs.length}
            theme={theme}
          />
        </div>
      </div>
    </div>
  );
};

export default JobSearch;
