import React, { useEffect } from 'react';
import { Paper } from '@mui/material';

import { Theme } from '../../types/talent-rerouting';
import JobSectionNavigationButton from './JobSearchNavigationButton';

const SearchFooter = ({
  sameCount = 0,
  similarCount = 0,
  stretchCount = 0,
  sameIsVisible,
  similarIsVisible,
  stretchIsVisible,
  theme,
  onClick,
}: {
  sameCount?: number;
  similarCount?: number;
  stretchCount?: number;
  sameIsVisible?: boolean;
  similarIsVisible?: boolean;
  stretchIsVisible?: boolean;
  theme?: Theme;
  onClick: (index: number) => void;
}) => {
  const [focused, setFocused] = React.useState<number>(0);
  const [ignore, setIgnore] = React.useState<boolean>(false); // for click event scroll

  const handleClick = (index: number) => {
    setIgnore(true);
    setTimeout(() => setIgnore(false), 500); // allow scroll to finish resetting focus detection in use effect
    setFocused(index);
    onClick(index);
  };

  useEffect(() => {
    if (!ignore) {
      if (sameIsVisible && focused !== 1 && similarIsVisible) {
        setFocused(0);
      } else if (similarIsVisible && focused !== 2 && stretchIsVisible) {
        setFocused(1);
      } else if (stretchIsVisible) {
        setFocused(2);
      }
    }
  }, [sameIsVisible, similarIsVisible, stretchIsVisible, ignore, focused]);

  useEffect(() => {
    if (sameCount > 0) {
      setFocused(0);
    } else if (similarCount > 0) {
      setFocused(1);
    } else if (stretchCount > 0) {
      setFocused(2);
    }
  }, [sameCount, similarCount, stretchCount]);

  return (
    <div
      style={{
        position: 'sticky',
        bottom: '16px',
        width: '100%',
        boxSizing: 'content-box',
        maxWidth: '100vw',
        zIndex: 10,
      }}
    >
      <Paper
        style={{
          borderRadius: 50,
          display: 'flex',
          justifyContent: 'space-between',
          boxSizing: 'content-box',
        }}
      >
        <JobSectionNavigationButton
          key={0}
          label="Same"
          count={sameCount}
          focused={focused === 0}
          theme={theme}
          onClick={() => handleClick(0)}
        />
        <JobSectionNavigationButton
          key={1}
          label="Similar"
          count={similarCount}
          focused={focused === 1}
          theme={theme}
          onClick={() => handleClick(1)}
        />
        <JobSectionNavigationButton
          key={2}
          label="Growth"
          count={stretchCount}
          focused={focused === 2}
          theme={theme}
          onClick={() => handleClick(2)}
        />
      </Paper>
    </div>
  );
};
export default SearchFooter;
